@import url(https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/* User interaction
 * ========================================================================== */

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-bold-webfont.d76ff367.eot);
  src: url(/static/media/wotfard-bold-webfont.d76ff367.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-bold-webfont.5712af5b.woff2) format('woff'),
    url(/static/media/wotfard-bold-webfont.101d854c.ttf) format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-semibold-webfont.8901edaf.eot);
  src: url(/static/media/wotfard-semibold-webfont.8901edaf.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-semibold-webfont.5a44d1fe.woff2) format('woff'),
    url(/static/media/wotfard-semibold-webfont.ab0633e6.ttf) format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-medium-webfont.2a31bf56.eot);
  src: url(/static/media/wotfard-medium-webfont.2a31bf56.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-medium-webfont.fe8ead49.woff2) format('woff'),
    url(/static/media/wotfard-medium-webfont.4e3c428b.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-regular-webfont.409bf748.eot);
  src: url(/static/media/wotfard-regular-webfont.409bf748.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-regular-webfont.46881ba2.woff2) format('woff'),
    url(/static/media/wotfard-regular-webfont.5ff2a2f3.ttf) format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-light-webfont.a76ce261.eot);
  src: url(/static/media/wotfard-light-webfont.a76ce261.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-light-webfont.ae7cce29.woff2) format('woff'),
    url(/static/media/wotfard-light-webfont.833e8e94.ttf) format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-extralight-webfont.b39d17cf.eot);
  src: url(/static/media/wotfard-extralight-webfont.b39d17cf.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-extralight-webfont.0103fb78.woff2) format('woff'),
    url(/static/media/wotfard-extralight-webfont.870c254b.ttf) format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-thin-webfont.d57151d9.eot);
  src: url(/static/media/wotfard-thin-webfont.d57151d9.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-thin-webfont.74e53d13.woff2) format('woff'),
    url(/static/media/wotfard-thin-webfont.ff56d36f.ttf) format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* ============================================================

ITALIC

============================================================ */

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-bolditalic-webfont.c49473e8.eot);
  src: url(/static/media/wotfard-bolditalic-webfont.c49473e8.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-bolditalic-webfont.1694413d.woff2) format('woff'),
    url(/static/media/wotfard-bolditalic-webfont.623688a6.ttf) format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-semibolditalic-webfont.579c9f20.eot);
  src: url(/static/media/wotfard-semibolditalic-webfont.579c9f20.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-semibolditalic-webfont.5be170f7.woff2) format('woff'),
    url(/static/media/wotfard-semibolditalic-webfont.793d232b.ttf) format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-mediumitalic-webfont.a5409877.eot);
  src: url(/static/media/wotfard-mediumitalic-webfont.a5409877.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-mediumitalic-webfont.f789d752.woff2) format('woff'),
    url(/static/media/wotfard-mediumitalic-webfont.274677b8.ttf) format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-regularitalic-webfont.e82e259d.eot);
  src: url(/static/media/wotfard-regularitalic-webfont.e82e259d.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-regularitalic-webfont.d50a38b0.woff2) format('woff'),
    url(/static/media/wotfard-regularitalic-webfont.715acdcf.ttf) format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-lightitalic-webfont.26a0c628.eot);
  src: url(/static/media/wotfard-lightitalic-webfont.26a0c628.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-lightitalic-webfont.0be8b3f9.woff2) format('woff'),
    url(/static/media/wotfard-lightitalic-webfont.26b70ca9.ttf) format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-extralightitalic-webfont.96c7145b.eot);
  src: url(/static/media/wotfard-extralightitalic-webfont.96c7145b.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-extralightitalic-webfont.e333fe23.woff2) format('woff'),
    url(/static/media/wotfard-extralightitalic-webfont.6daadb22.ttf) format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url(/static/media/wotfard-thinitalic-webfont.9e018923.eot);
  src: url(/static/media/wotfard-thinitalic-webfont.9e018923.eot?#iefix)
      format('embedded-opentype'),
    url(/static/media/wotfard-thinitalic-webfont.b506cff5.woff2) format('woff'),
    url(/static/media/wotfard-thinitalic-webfont.13ed83ac.ttf) format('truetype');
  font-weight: 100;
  font-style: italic;
}

