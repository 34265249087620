@import-normalize;

@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-bold-webfont.eot');
  src: url('./fonts/wotfard-bold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-bold-webfont.woff2') format('woff'),
    url('./fonts/wotfard-bold-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-semibold-webfont.eot');
  src: url('./fonts/wotfard-semibold-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-semibold-webfont.woff2') format('woff'),
    url('./fonts/wotfard-semibold-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-medium-webfont.eot');
  src: url('./fonts/wotfard-medium-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-medium-webfont.woff2') format('woff'),
    url('./fonts/wotfard-medium-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-regular-webfont.eot');
  src: url('./fonts/wotfard-regular-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-regular-webfont.woff2') format('woff'),
    url('./fonts/wotfard-regular-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-light-webfont.eot');
  src: url('./fonts/wotfard-light-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-light-webfont.woff2') format('woff'),
    url('./fonts/wotfard-light-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-extralight-webfont.eot');
  src: url('./fonts/wotfard-extralight-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-extralight-webfont.woff2') format('woff'),
    url('./fonts/wotfard-extralight-webfont.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-thin-webfont.eot');
  src: url('./fonts/wotfard-thin-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-thin-webfont.woff2') format('woff'),
    url('./fonts/wotfard-thin-webfont.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* ============================================================

ITALIC

============================================================ */

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-bolditalic-webfont.eot');
  src: url('./fonts/wotfard-bolditalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-bolditalic-webfont.woff2') format('woff'),
    url('./fonts/wotfard-bolditalic-webfont.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-semibolditalic-webfont.eot');
  src: url('./fonts/wotfard-semibolditalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-semibolditalic-webfont.woff2') format('woff'),
    url('./fonts/wotfard-semibolditalic-webfont.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-mediumitalic-webfont.eot');
  src: url('./fonts/wotfard-mediumitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-mediumitalic-webfont.woff2') format('woff'),
    url('./fonts/wotfard-mediumitalic-webfont.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-regularitalic-webfont.eot');
  src: url('./fonts/wotfard-regularitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-regularitalic-webfont.woff2') format('woff'),
    url('./fonts/wotfard-regularitalic-webfont.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-lightitalic-webfont.eot');
  src: url('./fonts/wotfard-lightitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-lightitalic-webfont.woff2') format('woff'),
    url('./fonts/wotfard-lightitalic-webfont.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-extralightitalic-webfont.eot');
  src: url('./fonts/wotfard-extralightitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-extralightitalic-webfont.woff2') format('woff'),
    url('./fonts/wotfard-extralightitalic-webfont.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-thinitalic-webfont.eot');
  src: url('./fonts/wotfard-thinitalic-webfont.eot?#iefix')
      format('embedded-opentype'),
    url('./fonts/wotfard-thinitalic-webfont.woff2') format('woff'),
    url('./fonts/wotfard-thinitalic-webfont.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}
